export const JobData = [
  {
    id: 1,
    title: "UI/UX Designer",
    team: "Teams",
    location: "Seattle, USA (Remote)",
    timeAgo: "1 day ago",
    applicants: 22,
    promoted: true,
  },
  {
    id: 2,
    title: "UI/UX Designer",
    team: "Teams",
    location: "Seattle, USA (Remote)",
    timeAgo: "1 day ago",
    applicants: 22,
    promoted: true,
  },
  {
    id: 3,
    title: "UI/UX Designer",
    team: "Teams",
    location: "Seattle, USA (Remote)",
    timeAgo: "1 day ago",
    applicants: 22,
    promoted: true,
  },
  {
    id: 4,
    title: "UI/UX Designer",
    team: "Teams",
    location: "Seattle, USA (Remote)",
    timeAgo: "1 day ago",
    applicants: 22,
    promoted: true,
  },
  {
    id: 5,
    title: "UI/UX Designer",
    team: "Teams",
    location: "Seattle, USA (Remote)",
    timeAgo: "1 day ago",
    applicants: 22,
    promoted: true,
  },
];
